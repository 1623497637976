/// <reference path="../../scripts/typings/jquery/jquery.d.ts" />
/// <reference path="../../scripts/typings/jssocials/index.d.ts" />
"use strict";

module DBS.Startup
{
    const targetWidth = 370;
    const slideMargin = 20;

    let slider: bxSlider;

    function setupEventTracking()
    {
    }

    function determineSlideWidth(): number
    {
        const wrap = document.getElementById("featured-properties") as HTMLElement;
        if (wrap == null)
        {
            return 0;
        }

        const count = Math.round(wrap.clientWidth / targetWidth);
        const width = Math.floor((wrap.clientWidth - (slideMargin * (count - 1))) / count);
        return width;
    }

    function generateOptions(currentSlide: number = 0): bxSliderOptions
    {
        return {
            slideMargin: slideMargin,
            minSlides: 1,
            maxSlides: 3,
            moveSlides: 3,
            slideWidth: determineSlideWidth(),
            auto: true,
            autoStart: true,
            pause: 4000,
            pager: true,
            startSlide: currentSlide,
            controls: false
        };
    }

    function resizeSlider()
    {
        if (slider == null)
        {
            return;
        }

        const currentSlide = slider.getCurrentSlide();
        slider.destroySlider();

        slider = $("#featured-properties .bxslider").bxSlider(generateOptions(currentSlide));
    }

    export function Initialize(ev: Event)
    {
        if($("#home-parallax").length === 1)
        {
            $(window).scroll(() =>
            {
                const scrollPos = $(window).scrollTop() - ($("#home-parallax").position().top + 60) + $("#home-parallax").height() + 30;
                $("#home-parallax-photo").css("background-position", `center ${scrollPos}px`);
            });
        }

        $("#toggle-navigation").click(function()
        {
            ev.preventDefault();
            $("#page-navigation").toggleClass("visible");
            $("#navigation-header").toggleClass("expanded");
            $(this).toggleClass("is-active");
        });

        if (typeof ($.fn.bxSlider) !== "undefined")
        {
            slider = $("#featured-properties .bxslider").bxSlider(generateOptions());
            window.addEventListener("resize", resizeSlider);
        }

        if (typeof ($.fn.jsSocials) !== "undefined")
        {
            const socialLinks = document.getElementsByClassName("item-social");

            for (let i = 0; i < socialLinks.length; i++)
            {
                const socialLink = socialLinks[i] as HTMLDivElement;

                const config = {
                    showLabel: false,
                    showCount: false,
                    url: socialLink.dataset["target"],
                    text: socialLink.dataset["desc"],
                    shares: ["email", "twitter", "facebook", "googleplus"]
                } as jsSocials.IConfig;

                $(socialLink).jsSocials(config);
            }

            setupEventTracking();
        }
    }
}

window.addEventListener("load", DBS.Startup.Initialize);